<template>
    <div class="convention-hall page">
        <van-nav-bar class="nav-bar" back :title="$route.query.name || ''" >
            
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
        </van-nav-bar>
        <div class="convention-item" v-if="$route.query.id">
            <div class="beautySecondContain beauty-router-view">
                <img v-for="(item, index) in beauty_list[choose_detail_id].second_img_list" :src="item" alt=""
                    :key="index">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            addlist: [],
            beauty_list: [{
                name: '서울',
                img: require('@/assets/choose/1.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/1/1.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/1/2.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/1/3.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/1/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/1/5.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/1/6.gif',
                ]
            }, {
                name: '부산',
                img: require('@/assets/choose/2.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/2.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/3.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/6.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/7.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/8.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/2/9.gif',]
            }, {
                name: '대구',
                img: require('@/assets/choose/3.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/3/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/3/2.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/3/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/3/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/3/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/3/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/3/8.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/3/9.gif',]
            }, {
                name: '인천',
                img: require('@/assets/choose/4.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/9.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/10.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/4/11.webp',]
            }, {
                name: '광주',
                img: require('@/assets/choose/5.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/9.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/5/10.webp',]
            }, {
                name: '대전',
                img: require('@/assets/choose/6.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/3.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/6.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/9.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/10.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/11.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/6/12.gif',]
            }, {
                name: '울산',
                img: require('@/assets/choose/7.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/1.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/2.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/3.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/7/9.webp',]
            }, {
                name: '세종특별자치시',
                img: require('@/assets/choose/8.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/4.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/9.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/8/10.webp',]
            }, {
                name: '제주도',
                img: require('@/assets/choose/9.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/3.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/6.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/7.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/8.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/9.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/10.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/9/11.gif',]
            }, {
                name: '경기도',
                img: require('@/assets/choose/10.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/4.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/9.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/10/10.webp',]
            }, {
                name: '강원도',
                img: require('@/assets/choose/11.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/4.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/11/9.webp',]
            }, {
                name: '충청북도',
                img: require('@/assets/choose/12.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/3.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/6.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/7.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/8.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/9.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/10.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/12/11.gif',]
            }, {
                name: '충청남도',
                img: require('@/assets/choose/13.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/3.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/4.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/13/9.webp']
            }, {
                name: '전라북도',
                img: require('@/assets/choose/14.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/4.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/5.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/8.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/9.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/14/10.webp']
            }, {
                name: '전라남도',
                img: require('@/assets/choose/15.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/15/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/15/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/15/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/15/4.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/15/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/15/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/15/7.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/15/8.webp',]
            }, {
                name: '경상북도',
                img: require('@/assets/choose/16.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/16/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/16/2.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/16/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/16/4.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/16/5.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/16/6.webp',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/16/7.webp',]
            }, {
                name: '경상남도',
                img: require('@/assets/choose/17.webp'),
                second_img_list: [
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/17/1.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/17/2.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/17/3.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/17/4.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/17/7.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/17/8.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/17/9.gif',
                    'https://cmc66.oss-ap-southeast-7.aliyuncs.com/avhoney/choose/17/10.gif',]
            }],
            choose_detail_id:this.$route.query.id
        };
    },
    methods:{
        
		back() {
			this.$router.go(-1)
		}
    }
};
</script>

<style lang="less" scoped>
.page {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    // background: url(../../assets/login-bg.png);
    background-size: cover;
    background-position: 50%;
}

.nav-bar {
    background: linear-gradient(90deg, #f87171, #f87171);
    height: 100px;
}

.van-nav-bar {
    line-height: 50px;
}

::v-deep .van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #ffffff;
    font-size: 35px;
}

::v-deep .van-nav-bar__content {
    height: 100px;
}

.van-sidebar {
    width: 180px;
}

// /deep/ .van-col{
// padding: 30px 0px;
// }
.convention-hall {
    display: flex;
    flex-direction: column;
    // bottom: 20px;
    // background: #f2f2f5;
}

::v-deep .van-tab {
    font-size: 30px;
    line-height: 100px;
    font-weight: bold;
}

::v-deep .van-tabs__line {
    background-color: #f87171;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
    padding: 0 23px;
}

.card {
    padding: 0.625rem;
    width: 95%;
    color: white;
    font-size: 0.8125rem;
    margin: 0.625rem auto;
    border-radius: 0.375rem;
    color: #fff;
    background-color: rgba(232, 121, 249, .5);
    font-weight: 700;
}

::v-deep .van-row--flex {
    height: 80px;
    line-height: 80px;
}

/deep/ .van-cell {
    padding: 30px 22px;
    font-size: 30px;
    line-height: 30px;
}

.rig-box {
    width: 95%;
    margin: 0.625rem auto;
}

.rig-title {
    color: #a21caf;
    font-size: 36px;
    font-weight: bold;
}

.rig-content {
    font-size: 30px;
    // margin-top: 10px;
    color: #000;
}

.address {
    width: 94%;
    margin: 0 auto;
}

.beautyFirstContain {
    width: calc(100% - 60px);
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap
}

.beautyFirstContain>div {
    width: 210px;
    height: 210px;
    position: relative;
    margin-bottom: 20px
}

.beautyFirstContain>div img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px
}

.beautyFirstContain>div .describe-div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
    font-weight: 700
}

.convention-item {
    height: calc(100% - 240px);
    overflow: auto;
}

.beautySecondContain img {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    display: block;
}
</style>
